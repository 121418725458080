<template>
  <login-form @clickLogin="clickLogin($event)" @signup="signup($event)"></login-form>
</template>
<script>
import AppLogin from "../../library/auth/login";
import axios from "axios";
import { mapActions, mapGetters } from "vuex";

export default {
  components: {
    "login-form": AppLogin,
  },
  data() {
    return {
      valid: false,
      url: process.env.VUE_APP_LOYALTY_API_URI,
      user: {},
      doctor: {},
    };
  },
  computed: {
    ...mapGetters({
      getAuth: "BFC/getAuth",
      getInfo: "BFC/getDoctorInfo",
    }),
  },
  mounted() {
    this.initial();
  },
  methods: {
    ...mapActions({
      auth: "BFC/doctorAuth",
      setInfo: "BFC/setDoctorInfo",
      setAllCases: "BFC/setAllCases",
      setAuth: "BFC/setAuth",
    }),
    async initial() {
      try {
        //
        await axios
          .post(
            this.url + "/v1/admin/getConfigByName",
            {
              code: "EXRATE",
              name: "EXCHANGE_RATE",
            }, // need to change
            {
              headers: {
                // Overwrite Axios's automatically set Content-Type
                "Content-Type": "application/json",
              },
            }
          )
          .then((response) => {
            console.log(response);
            //this.exchangeRate = response.data[0].cfg_value;
            if (response.data.length == 1) {
              localStorage.setItem("exchange_rate", response.data[0].cfg_value);
            } else {
              localStorage.setItem("exchange_rate", 1);
            }
          });
      } catch (err) {
        console.log(err);
        localStorage.setItem("exchange_rate", 1);
      }
    },

    async clickLogin(payload) {
      //console.log("login");
      //console.log(payload);
      try {
        //let payload
        await axios
          .post(
            this.url + "/api/bfc/v1/doctor/signin",
            payload, // need to change
            {
              headers: {
                // Overwrite Axios's automatically set Content-Type
                "Content-Type": "application/json",
              },
            }
          )
          .then(async (response) => {
            // console.log(response);
            console.log(response.data.doctor);
            if (response.data.statusCode == 0) {
              this.doctor = response.data;
              let payload = {
                _id: response.data.member._id,
                token: "",
              };
              let payloadAuth = {
                _id: response.data.member._id,
                authType: "Doctor",
                lastLogin: new Date(),
                token: "",
              };

              this.$cookies.set("Auth", payloadAuth);
              this.$cookies.set("Profile", response.data.member);
              this.$cookies.set("DOCINFO", response.data.doctor);

              await this.auth(payload);
              await this.setInfo(response.data);
              await this.setAuth(payloadAuth);
              localStorage.setItem("Profile",JSON.stringify(response.data))
              localStorage.setItem(
                "CURRENCY",
                response.data.doctor[0].usd == 1 ? "USD" : "THB"
              );

              if (localStorage.getItem("CURRENCY") == "THB") {
                localStorage.setItem("exchange_rate", 1);
              }
              //await this.listCases();
              // doctor info

              //console.log(this.getInfo);
              this.$router.push("/doctor/dashboard");
            } else {
              // show error
              console.log("error response");
              alert("Incorrect email or password");
            }
          });
      } catch (e) {
        //
        console.log(e);
      }
    },

    signup() {
      //console.log(e);
      this.$router.push("/doctor/sign-up");
    },
  },
};
</script>
